import PropTypes from 'prop-types'

import ButtonGroup from '../../../shared_components/core/buttons/ButtonGroup'
import * as Buttons from '../../../dashboard/src/components/blocks/Buttons'
import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'
import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import ConfirmationModal from '../../../shared_components/core/modals/components/ConfirmationModal'
import * as Inputs from '../../../dashboard/src/components/blocks/Inputs'
import Lorem from '../lib/Lorem'
import {ModalHeader, ModalHeaderBottomDivider} from '../../../shared_components/core/modals/ModalHeaders'
import Modals from '../../../shared_components/core/modals/Modals'
import successCheckmarkIcon from '../../../shared_assets/v2/success-checkmark.svg'
import {TextGutterMedium, TextGutterLarge} from '../../../dashboard/src/components/blocks/Texts'
import FeedbackForm from '../../../shared_components/feedback_form/FeedbackForm'
import WarningIconTriangle from '../../../dashboard/assets/WarningIconTriangle'

import {noop} from '../../../lib/tools'


const CustomCloser = ({closer}) => <div className='base-modal-closer' onClick={closer}>Close me!</div>
CustomCloser.propTypes = {closer: PropTypes.func}

const buttonTextLight = '#FFF' // Sass color variable from Style Guide


const ModalsSection = () => (
  <CollapsibleSection heading='Modals'>
    <BoxGutterMedium>
      <h2>Pop Up Modals</h2>

      <aside>
        <h3>Base</h3>
        <TextGutterMedium>
          <div><strong>Appearance:</strong> centered on the viewport with a minimum width of 480px.</div>
          <div><strong>When to use:</strong> modals with small amounts of content.</div>
          <div><strong>Example:</strong> "Are you sure you want to remove Yuri Orlov as a client?" modal currently in the pro app.</div>
        </TextGutterMedium>
        <aside>
          <Modals.LinkPopUpModal launcher={<a>Link pop up!</a>}>
            <Lorem paragraphCount={3} />
          </Modals.LinkPopUpModal>
          <CodeBlock>{CodeExamples.PopUpModals.Link}</CodeBlock>
        </aside>
        <aside>
          <Modals.ButtonPopUpModal launcher={<Buttons.Button>Button pop up!</Buttons.Button>}>
            <Lorem paragraphCount={3} />
          </Modals.ButtonPopUpModal>
          <CodeBlock>{CodeExamples.PopUpModals.Button}</CodeBlock>
        </aside>
      </aside>

      <aside>
        <h3>Confirm</h3>
        <TextGutterMedium>
          <div><strong>When to use:</strong> require the user to verify or accept an action.</div>
          <div><strong>Required props:</strong> launcher, children.</div>
          <div><strong>Optional props:</strong> closerComponent, showCloser.</div>
        </TextGutterMedium>
        <Modals.LinkConfirmPopUpModal launcher={<a>Confirm pop up!</a>}>
          <TextGutterMedium>
            This is a Confirm Pop Up Modal. It should be used when you want to display some information to the user and ask them to
            decline or accept something in response. Generally this type of modal is expected to contain text, a Cancel button, and
            an Accept button. Most of the time this type of modal will not include a heading, but if necessary one can be included.
            The closer X can be suppressed by passing a showCloser prop of false.
          </TextGutterMedium>
          <ButtonGroup>
            <Buttons.Cancel />
            <Buttons.Accept />
          </ButtonGroup>
        </Modals.LinkConfirmPopUpModal>
        <CodeBlock>{CodeExamples.PopUpModals.Confirm}</CodeBlock>
      </aside>

      <aside>
        <h3>Confirmation Modal</h3>
        <TextGutterMedium>
          <div>
            <strong>When to use: </strong>
            used to show a message asking to confirm that the user wishes to perform an action (commonly used when confirming a deletion/removal of a record).
          </div>
          <div>
            <strong>Required props: </strong>
            <code>confirmationText (string)</code>
          </div>
          <div>
            <strong>Optional props: </strong>
            <code>cancelButtonText (string)</code>,
            <code>closerComponent (func)</code>,
            <code>confirmButtonText (string)</code>,
            <code>onConfirm (func)</code>,
            <code>processing (bool)</code>,
            <code>showModal (bool)</code>,
            <code>toggleModal (func)</code>
          </div>
        </TextGutterMedium>
        <aside>
          <ConfirmationModal
            cancelButtonText='NO! STAHP!'
            confirmationText='Are you sure you want to delete XYZ? All information associated with it will be removed.'
            confirmButtonText='Yes, delete'
            launcher={<Buttons.Button>Confirm</Buttons.Button>}
            onConfirm={noop()}
            toggleModal={noop()}
          />
          <CodeBlock>{CodeExamples.PopUpModals.Confirmation}</CodeBlock>
        </aside>
      </aside>

      <aside>
        <h3>Prompt</h3>
        <TextGutterMedium>
          <div><strong>When to use:</strong> require the user to input and submit information.</div>
          <div><strong>Required props:</strong> launcher, children.</div>
          <div><strong>Optional props:</strong> closerComponent, showCloser.</div>
        </TextGutterMedium>
        <Modals.LinkPromptPopUpModal launcher={<a>Prompt pop up!</a>}>
          <header>
            <h2>h2 Prompt Heading</h2>
          </header>
          <TextGutterMedium>
            This is a Prompt Pop Up Modal. It should be used when you want a user to input and then submit information via a form.
            Generally this type of modal is expected to contain a heading, a form, a Cancel button, and a submit button (technically this
            will be an Accept button). The closer X can be suppressed by passing a showCloser prop of false.
          </TextGutterMedium>
          <form>
            <Inputs.Text placeholder='Placeholder Text' />
          </form>
          <Buttons.Accept>Submit</Buttons.Accept>
        </Modals.LinkPromptPopUpModal>
        <CodeBlock>{CodeExamples.PopUpModals.Prompt}</CodeBlock>
      </aside>

      <aside>
        <h3>Success Modal</h3>
        <TextGutterMedium>
          <div><strong>When to use:</strong> used to show a successful confirmation after a user's action.</div>
          <div><strong>Required props:</strong> launcher, children.</div>
          <div><strong>Optional props:</strong> closerComponent, showCloser.</div>
        </TextGutterMedium>
        <aside>
          <Modals.SuccessPopUpModal launcher={<Buttons.Button>Success pop up!</Buttons.Button>}>
            <header>
              <img src={successCheckmarkIcon} alt='Success checkmark' />
              <h2>h2 Success Modal Heading</h2>
            </header>
            <TextGutterMedium>Hooray! You are seeing our Success pop up modal!</TextGutterMedium>
            <TextGutterMedium>You will normally see this modal to confirm an action - for example - sending an invitaiton to be your Deputy.</TextGutterMedium>
            <Buttons.Button>Done</Buttons.Button>
          </Modals.SuccessPopUpModal>
          <CodeBlock>{CodeExamples.PopUpModals.Success}</CodeBlock>
        </aside>
      </aside>

      <aside>
        <h3>Warning Modal</h3>
        <TextGutterMedium>
          <div><strong>When to use:</strong> used to show a warning message after a user's action.</div>
          <div><strong>Required props:</strong> launcher, children.</div>
          <div><strong>Optional props:</strong> closerComponent, showCloser.</div>
        </TextGutterMedium>
        <aside>
          <Modals.WarningPopUpModal launcher={<Buttons.Button>Warning pop up!</Buttons.Button>}>
            <header>
              <WarningIconTriangle className='warning-icon' />
              <h2>h2 Warning Modal Heading</h2>
            </header>
            <TextGutterLarge>The warning pop up modal should be used to communicate an important message to the user and confirming their action - for example - removing a Deputy from an Everplan.</TextGutterLarge>
            <ButtonGroup>
              <Buttons.Cancel />
              <Buttons.Remove />
            </ButtonGroup>
          </Modals.WarningPopUpModal>
          <CodeBlock>{CodeExamples.PopUpModals.Warning}</CodeBlock>
        </aside>
      </aside>

      <aside>
        <h3>User Feedback Modal</h3>
        <TextGutterMedium>
          <div><strong>When to use:</strong> Aquire feedback of user experience.</div>
          <div><strong>Required props:</strong> launcher, children.</div>
          <div><strong>Optional props:</strong> closerComponent, closerFillColor, showCloser.</div>
        </TextGutterMedium>
        <aside>
          <Modals.FeedbackModal closerFillColor={buttonTextLight} launcher={<Buttons.Button>User Feedback Modal</Buttons.Button>}>
            <header>
              <h2>How was your experience adding a Deputy?</h2>
            </header>
            <FeedbackForm checkboxLabel="I'm ok with Everplans reaching out to me via email to gather additional details about my experience.">
              <Buttons.Button>Submit</Buttons.Button>
            </FeedbackForm>
          </Modals.FeedbackModal>
          <CodeBlock>{CodeExamples.PopUpModals.Feedback}</CodeBlock>
        </aside>
      </aside>

      <aside>
        <h3>Large Pop Up Modals</h3>
        <TextGutterMedium>
          <div><strong>Appearance:</strong> centered on the viewport with a set width of 500px in tablet/desktop and full viewport in mobile.</div>
          <div><strong>Required props:</strong> children (A header (Use either the <code>ModalHeader</code> or <code>ModalHeaderBorderDivider</code>) is required within the children props)</div>
          <div><strong>When to use:</strong> require the user to input form data and submit information.</div>
          <div><strong>Example:</strong> new item edit form, credit card info.</div>
        </TextGutterMedium>
        <aside>
          <Modals.LinkPopUpModalLarge launcher={<a>Link pop up modal!</a>}>
            <ModalHeader className='a-class-selector' heading='h2 Large Modal Heading' banner='Hello, I am a banner' />
            <TextGutterMedium>
              This is a Large Pop Up Modal. It should be used when you want to display a form or a large amount of content.
              Generally this type of modal is expected to contain a heading, a form, a Cancel and Submit button. As an option you can add a banner above the heading.
            </TextGutterMedium>
            <Lorem paragraphCount={8} />
            <ButtonGroup>
              <Buttons.Cancel />
              <Buttons.Accept />
            </ButtonGroup>
          </Modals.LinkPopUpModalLarge>
          <CodeBlock>{CodeExamples.PopUpModals.Large.Link}</CodeBlock>
        </aside>
        <aside>
          <Modals.ButtonPopUpModalLarge launcher={<Buttons.Button>Pop Up!</Buttons.Button>}>
            <ModalHeaderBottomDivider className='a-class-selector' heading='h2 Large Modal Heading with Border Divider' banner='Hello, I am a banner' />
            <TextGutterMedium>
              This is a Large Pop Up Modal. It should be used when you want to display a form or a large amount of content.
              Generally this type of modal is expected to contain a heading, a form, a Cancel and Submit button. As an option you can add a banner above the heading.
            </TextGutterMedium>
            <Lorem paragraphCount={8} />
            <ButtonGroup>
              <Buttons.Cancel />
              <Buttons.Accept />
            </ButtonGroup>
          </Modals.ButtonPopUpModalLarge>
          <CodeBlock>{CodeExamples.PopUpModals.Large.Button}</CodeBlock>
        </aside>
      </aside>
      {/* // Currently not using the Sliding Modals and is replaced by the Large Pop Up Modal. --KW
      <aside>
        <h3>Sliding Modals</h3>
        <TextGutterMedium>
          <div>
            <strong>Appearance:</strong> stuck to the top, right, and bottom of the viewport and have a maximum width of 768px. The maximum width is the
            same as an iPad.
          </div>
          <div><strong>When to use:</strong> modals with a larger amount of content.</div>
          <div><strong>Example:</strong> display a preview of a specific item within an Everplan on the new consumer item pages.</div>
        </TextGutterMedium>
        <aside>
          <Modals.LinkSlidingModal launcher={<a>Link sliding modal!</a>} heading='h2 Sliding Modal Heading'>
            <Lorem paragraphCount={2} />
            <Inputs.Text placeholder='Placeholder Text' />
            <ButtonGroup>
              <Buttons.Cancel />
              <Buttons.Accept />
            </ButtonGroup>
          </Modals.LinkSlidingModal>
          <CodeBlock>{CodeExamples.SlidingModals.Link}</CodeBlock>
        </aside>
        <aside>
          <Modals.ButtonSlidingModal launcher={<Buttons.Button>Sliding!</Buttons.Button>} heading='h2 Sliding Modal Heading'>
            <Lorem paragraphCount={2} />
            <Inputs.Text placeholder='Placeholder Text' />
            <ButtonGroup>
              <Buttons.Cancel />
              <Buttons.Accept />
            </ButtonGroup>
          </Modals.ButtonSlidingModal>
          <CodeBlock>{CodeExamples.SlidingModals.Button}</CodeBlock>
        </aside>
      </aside>
      */}
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default ModalsSection
