import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'
import CollapsibleSection from './CollapsibleSection'
import ColorSwatch from '../lib/ColorSwatch'
import colors from '../../../dashboard/styles/abstracts/colors.module.scss'

const COLORS = {
  'Primary Colors': [
    {name: '$everplansBlue', value: '#0E689C'},
    {name: '$everplansGreen', value: '#4BA66A'},
    {name: '$everplansHighlight', value: '#15A9FF'},
    {name: '$everplansIndigo', value: '#14158B'},
    {name: '$everplansRed', value: colors.everplansRed}
  ],

  'Background Colors': [
    {name: '$boxBackground', value: '#FFF', useDarkText: true},
    {name: '$background', value: '#F7F8FB', useDarkText: true},
    {name: '$discountBackground', value: '#E6F1C8', useDarkText: true},
    {name: '$inputDisabledBackground', value: '#ECECEC', useDarkText: true},
    {name: '$itemResponseBackground', value: '#F7F7F7', useDarkText: true},
    {name: '$modalHeaderBackground', value: '#14158B', useDarkText: false},
    {name: '$messageNotificationBackground', value: '#FFF9AD', useDarkText: true},
    {name: '$pendingStatusBackground', value: '#FF8914', useDarkText: false},
    {name: '$selectButtonBackground', value: '#FBFCFD', useDarkText: true},
    {name: '$tooltipBackground', value: '#262626', useDarkText: false},
    {name: '$hoverBackground', value: '#e5eaf1', useDarkText: true},
    {name: '$smallModuleBackground', value: '#ebf9f6', useDarkText: true}
  ],

  'Border Colors': [
    {name: '$boxBorder', value: '#DAE1EB', useDarkText: true},
    {name: '$dashboardBorder', value: '#EEE', useDarkText: true},
    {name: '$headerBarDivider', value: '#0B527A'},
    {name: '$inputBorder', value: '#D6DDE1', useDarkText: true},
    {name: '$inputDisabledBorder', value: '#E2E2E2', useDarkText: true},
    {name: '$navBorder', value: '#0C5885'},
    {name: '$radioButtonBorderMobile', value: '#009AF3'},
    {name: '$textboxBorder', value: '#D6D6D6', useDarkText: true},
    {name: '$textboxBorderDivider', value: '#D8D8D8', useDarkText: true},
    {name: '$textboxBorderDividerLight', value: '#F0F0F0', useDarkText: true},
    {name: '$textboxBorderError', value: colors.textBoxBorderError},
    {name: '$brightGrey', value: '#F2F2F2'}
  ],

  'Button Colors: Base': [
    {name: '$buttonActiveBackground', value: '#14158B'},
    {name: '$buttonBackground', value: '#14158B'},
    {name: '$buttonDisabledBackground', value: 'rgba(20, 21, 139, 0.2)'},
    {name: '$buttonHoverBackground', value: '#5B5DFA'}
  ],

  'Button Colors: Add': [
    {name: '$buttonAddBackground', value: '#FFF', useDarkText: true},
    {name: '$buttonAddBorder', value: '#BFBFBF'}
  ],

  'Button Colors: Add More': [
    {name: '$buttonAddMoreActive', value: '#007BC2'},
    {name: '$buttonAddMoreBackground', value: '#FFF', useDarkText: true},
    {name: '$buttonAddMoreBorder', value: '#BFBFBF'},
    {name: '$buttonAddMoreHover', value: '#15A9FF'}
  ],

  'Button Colors: Cancel': [
    {name: '$buttonCancelActiveBackground', value: '#595959'},
    {name: '$buttonCancelBackground', value: '#A6A6A6'},
    {name: '$buttonCancelHoverBackground', value: '#C7C7C7'}
  ],

  'Button Colors: Remove': [
    {name: '$buttonRemoveActiveBackground', value: colors.buttonRemoveActiveBackground},
    {name: '$buttonRemoveBackground', value: colors.buttonRemoveBackground},
    {name: '$buttonRemoveHoverBackground', value: colors.buttonRemoveHoverBackground}
  ],

  'Button Colors: Other': [
    {name: '$buttonClientOnboardingBackground', value: '#157BC2'}
  ],

  'Text Colors': [
    {name: '$bodyText', value: '#515151'},
    {name: '$buttonAddMoreText', value: '#007BC2'},
    {name: '$buttonTextDark', value: '#14158B'},
    {name: '$buttonTextLight', value: '#FFF', useDarkText: true},
    {name: '$errorText', value: colors.errorText},
    {name: '$ghostText', value: '#989B9B'},
    {name: '$headerText', value: '#262626'},
    {name: '$helperText', value: '#9B9B9B'},
    {name: '$inputDisabledPlaceholderText', value: '#A8A8A8'},
    {name: '$inputPlaceholderText', value: '#939393'},
    {name: '$link', value: '#007BC2'},
    {name: '$linkHover', value: '#15A9FF'},
    {name: '$textDisabled', value: '#939393'},
    {name: '$textLight', value: '#FFF', useDarkText: true},
    {name: '$textWarning', value: colors.textWarning}
  ],

  'Badge Colors': [
    {name: '$badgeArticleBackground', value: '#6F15FF'},
    {name: '$badgeChecklistBackground', value: '#15A9FF'},
    {name: '$badgeGuideBackground', value: '#EFB643'}
  ],

  'File Upload Status Colors': [
    {name: '$fileStatusBackground', value: '#2A2A2A'},
    {name: '$fileStatusBorder', value: '#666'},
    {name: '$fileStatusButton', value: '#9B9B9B'},
    {name: '$fileStatusText', value: '#2A2A2A'}
  ],

  'Other Colors': [
    {name: '$boxShadow', value: 'rgba(0, 0, 0, 0.05)', useDarkText: true},
    {name: '$modalOverlay', value: 'rgba(0, 0, 0, 0.72)'},
    {name: '$nestedModalOverlay', value: 'rgba(0, 0, 0, 0.2)'}
  ]
}


const ColorsSection = () => (
  <CollapsibleSection heading='Colors'>
    <BoxGutterMedium>
      {
        Object.keys(COLORS).map(
          colorCategory => (
            <div key={colorCategory}>
              <h3>{colorCategory}</h3>
              <div className='colors-list flex-container'>
                {COLORS[colorCategory].map(color => <ColorSwatch color={color} key={color.name} />)}
              </div>
            </div>
          )
        )
      }
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default ColorsSection
